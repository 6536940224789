.rich-text-editor-wrapper {
    border: 1px solid #f5f5f5f5;
    border-radius: 0.25rem;
    margin-top: 5px;
    margin-bottom: 5px;

    .rich-text-editor {
        margin-top: 5px;
        padding-left: 15px;
        min-height: 150px;

        &.valid {
            border: 1px solid;
            border-radius: 0.25rem;
            border-color: #4dbd74;
            transition: border-color .2s ease;
        }

        &.error {
            border: 1px solid;
            border-radius: 0.25rem;
            border-color: #f86c6b;
            transition: border-color .2s ease;
        }

    }
}

.error-message {
    font-size: 80%;
    color: #f86c6b;
}
